//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { baseApiUrl, homeBaseApiUrl } from "@/utils";
import { getValidToken } from "@/utils/storage";
export default {
  data: function data() {
    return {
      meauListleft: [{
        icon: require("./../image/tuzhishenhe.png"),
        h1: '图纸审核',
        h2: '2D图纸的审核工具',
        param: 'DrawingReview'
      }, {
        icon: require("./../image/moxingshenhe.png"),
        h1: '模型审核',
        h2: 'BIM模型(含正向出图)的审核工具',
        param: 'ModelReview'
      }, {
        icon: require("./../image/shejibiangeng2.png"),
        h1: '产品审核',
        h2: '问题管理/数据统计(网页端)',
        param: 'longforMeau/issue'
      }],
      meauListRight: [],
      url: ''
    };
  },
  methods: {
    openLink: function openLink(item) {
      var baseurl = baseApiUrl() + '/#/';
      var homeUrl = homeBaseApiUrl();

      if (item == 'auditIframe') {
        var path = baseurl + item + '?url=' + encodeURIComponent(this.url);
      } else {
        if (item == '/yts/workbench/work') {
          var path = homeUrl + item;
        } else {
          var path = baseurl + item;
        }

        window.cefSharpBrower && window.cefSharpBrower.openURL(path);
      } // console.log(path,'item');
      // window.cefSharpBrower && window.cefSharpBrower.openURL(path) //暂时移动上去

    },
    openLink1: function openLink1(item) {
      if (item != 'FastConsult') {
        if (item === 'longforMeau/issue') {
          var _this$$route$query;

          var token = getValidToken() || ((_this$$route$query = this.$route.query) === null || _this$$route$query === void 0 ? void 0 : _this$$route$query.auth);
          var suffix = token ? "?auth=".concat(token) : "";
          var baseurl = baseApiUrl() + '#/';
          var path = baseurl + item + suffix;
          window.cefSharpBrower && window.cefSharpBrower.openURL(path);
        } else {
          window.cefSharpBrower && window.cefSharpBrower.launchWindow(item);
        }
      }
    }
  },
  mounted: function mounted() {
    if (!!this.$route.query.prjID) {
      this.url = "/longforMeau/issue?prjID=" + this.$route.query.prjID;
    }
  }
};