//
//
//
//
//
//
//
//
//
import entrys from './entrys/entrys.vue'; // import shapetransform from './shapetransform/shapetransform.vue'

export default {
  components: {
    entrys: entrys // shapetransform

  }
};