var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "entrys" } }, [
    _c("div", { staticClass: "conter" }, [
      _c("div", { staticClass: "left" }, [
        _c(
          "div",
          { staticClass: "box" },
          _vm._l(_vm.meauListleft, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "item",
                class: { noTurn: !!item.disabled },
                on: {
                  click: function($event) {
                    return _vm.openLink1(item.param)
                  }
                }
              },
              [
                _c("img", { attrs: { src: item.icon, alt: "" } }),
                _c("p", { staticClass: "p1" }, [_vm._v(_vm._s(item.h1))]),
                _c("p", { staticClass: "remark" }, [_vm._v(_vm._s(item.h2))])
              ]
            )
          }),
          0
        )
      ]),
      _c("div", { staticClass: "right" }, [
        _c(
          "div",
          { staticClass: "box" },
          _vm._l(_vm.meauListRight, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "item2",
                class: { noTurn: !!item.disabled },
                on: {
                  click: function($event) {
                    return _vm.openLink(item.param)
                  }
                }
              },
              [
                _c("div", { staticClass: "boxTop" }, [
                  _c("img", { attrs: { src: item.icon, alt: "" } }),
                  _c("p", [_vm._v(_vm._s(item.h1))])
                ]),
                !!item.h2
                  ? _c("p", { staticClass: "remark" }, [
                      _vm._v(_vm._s(item.h2))
                    ])
                  : _vm._e()
              ]
            )
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }